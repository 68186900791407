import React, { Component } from 'react';

import './App.css';
import Clock from '../components/Clock.js';
import drawMap from '../d3/drawMap.js';
import { ReactComponent as Map } from '../europe-map.svg';
import Navigation from '../components/Navigation.js';
import Thankyou from '../components/Thankyou.js';
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      britishWind: 0,
      irishDemand: 0,
      irishWind: 0,
      interconnectors: {}
    };
  }

  componentDidMount() {
    this.fetchData()
    this.interval = setInterval(() => {
      this.fetchData()
    }, 10000);
  }

  fetchData(){
    fetch('/api/AllData')
      .then((response) => response.json())
      .then((data) => this.setState({data:data}))
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="App">
        <Navigation />
        <Map />
        {drawMap(this.state.data)}
        <Clock />
        <Thankyou />
      </div>
    );
  }
}

export default App;
