const interconnectorsPathCoordinates = {
  intew:{
    lineCoordinates:[[145, 300], [125, 295]],
    textCoordinates:{
      x:130,
      y:293
    }
  },
  intfr:{
    lineCoordinates:[[175, 338], [190, 355]],
    textCoordinates:{
      x:191,
      y:361
    }
  },
  intirl:{
    lineCoordinates:[[150, 255], [135, 268]],
    textCoordinates:{
      x:145,
      y:266
    }
  },
  intned:{
    lineCoordinates:[[195, 320], [222, 325]],
    textCoordinates:{
      x:203,
      y:318
    }
  },
  intnem:{
    lineCoordinates:[[194, 336], [210, 340]],
    textCoordinates:{
      x:200,
      y:335
    }
  },
  intelec:{
    lineCoordinates:[[185, 338], [195, 350]],
    textCoordinates:{
      x:198,
      y:352
    }
  },
  intifa2:{
    lineCoordinates:[[160, 338], [180, 360]],
    textCoordinates:{
      x:147,
      y:355
    }
  },
  intnsl:{
    lineCoordinates:[[177, 280], [244, 206]],
    textCoordinates:{
      x:187,
      y:240
    }
  },
}
export default interconnectorsPathCoordinates;
