import React, { Component } from 'react';
import './Thankyou.css';
class Navigation extends Component {

  render() {
    return (
        <div id='thankyou'>
         	Data courtesy of Swissgrid, EirGrid and Elexon
	</div>
    );
  }

}

export default Navigation;
