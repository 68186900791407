import React, { Component } from 'react';
import './Navigation.css';
class Navigation extends Component {

  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main-navigation">
          <div className="navbar-brand">
              <button  className="navbar-item logo left">
                  <img src="/img/EI-logo.jpg" alt="Energy Institute Real Time Display" />
              </button >
              <a className="navbar-item">Energy Institute Real Time Display</a>
              <button  className="navbar-item logo right">
                  <img src="/img/Nexsys-logo.png" alt="Energy Institute Real Time Display" height="70" className='right' />
              </button >
          </div>
      </nav>

    );
  }

}

export default Navigation;
