import * as d3 from 'd3';
import textCoordinates from '../config/text-coordinates.js';
import interconnectorsPathCoordinates from '../config/interconnectors-coordinates.js';

const drawMap = (props) =>{
  d3.select(".new-d3-components").selectAll("*").remove();
  colorMap();
  if(props)
  {
console.log(props);
    drawSquare({x:76, y:232, height:36, width:75});
    drawSquare({x:176, y:277, height:36, width:75});
    drawSquare({x:223, y:343, height:10, width:75});
    drawTextandValues(props)
    drawInterconnector(props)

  }
}

function drawTextandValues(props){
  for(let key of Object.keys(textCoordinates)){
    if(props[key])
    {
      var text = textCoordinates[key].text +': ' + props[key].value + textCoordinates[key].unit;
      drawText(
        textCoordinates[key].textCoordinates,
        text,
        ['demand']
      );
    }
  }
}

function drawInterconnector(props) {
  for(let key of Object.keys(interconnectorsPathCoordinates)){
    if(typeof props.interconnectors[key] !== 'undefined')
    {
      var className = interconnectorClassName(props.interconnectors[key])
      drawLine(interconnectorsPathCoordinates[key].lineCoordinates, className);
      drawText(
        interconnectorsPathCoordinates[key].textCoordinates,
        Math.abs(props.interconnectors[key])+'MW'
      );
    }
  }
}

function interconnectorClassName(interconnectorValue){
  if(interconnectorValue<0) {
     return'reverse';
  }else if(interconnectorValue === 0){
    return 'zero';
  }
  return '';
}

function colorMap(){
  var color_scale = d3.scaleOrdinal()
  .range(d3.schemeYlGn[9]);

  d3.selectAll('.land-inner')
  .attr('fill', function(d){
    return color_scale( Math.random());
  });
}

function drawText(coordinates, text, className){
  var classNameJoined = (className)? className.join(' ') : ''
  d3.select(".new-d3-components").append("text")
   .attr("x", coordinates.x)
   .attr("y", coordinates.y)
   .attr("class", "label " + classNameJoined )
   .text( `${text}`);
}

function drawSquare(attr){
  d3.select(".new-d3-components")
    .append('rect')
    .attr("x", attr.x)
    .attr("y", attr.y)
    .attr("height", attr.height)
    .attr("width", attr.width)
    .attr("fill", '#ffffff')
    .style("opacity", .3)
}

function drawLine(coordinates, className=''){
  var lineGenerator = d3.line()
  .x(function(d) {
    return d[0];
  })
  .y(function(d){
    return d[1];
  })
  .curve(d3.curveBasis);

  var line = lineGenerator(coordinates)
  d3.select(".new-d3-components")
  .append("path")
  .attr("class", "backG")
  .attr('d', line);

  d3.select(".new-d3-components")
  .append("path")
  .attr("class", "line " +className)
  .attr('d', line);
}
export default drawMap;
