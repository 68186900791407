const textCoordinates = {
  britishFrequency:{
    textCoordinates:{
      x:180,
      y:285
    },
    text: 'FREQUENCY',
    unit: 'Hz'
  },
  britishDemand: {
    textCoordinates:{
      x:180,
      y:293
    },
    text: 'DEMAND',
    unit: 'MW'
  },
  britishWind:{
    textCoordinates:{
      x:180,
      y:301
    },
    text: 'WIND',
    unit: 'MW'
  },
  britishSolar:{
    textCoordinates:{
      x:180,
      y:309
    },
    text: 'Solar',
    unit: 'MW'
  },
  irishFrequency:{
    textCoordinates:{
      x:80,
      y:240
    },
    text: 'FREQUENCY',
    unit: 'Hz'
  },
  irishDemand:{
    textCoordinates:{
      x:80,
      y:248
    },
    text: 'DEMAND',
    unit: 'MW'
  },
  irishWind:{
    textCoordinates:{
      x:80,
      y:256
    },
    unit: 'MW',
    text: 'WIND'
  },
  irishSnsp:{
    textCoordinates:{
    x:80,
      y:264
    },
    unit: '%',
    text: 'SNSP'
  },
  europeanFrequency:{
    textCoordinates:{
    x:224,
      y:350
    },
    unit: 'Hz',
    text: 'ENTSO-E Freq'
  },
}
export default textCoordinates;
